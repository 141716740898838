import type { SVGProps } from "react"

export const AmpersandMarkIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="50"
    height="28"
    viewBox="0 0 50 28"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M40.7801 16.3121H44.3262L50 8.27423H24.4681L20.6856 13.5934L18.5579 11.4657L26.7139 0H13.5934C6.14657 0 0 6.02837 0 13.5934C0 21.0402 6.02837 27.1868 13.5934 27.1868H33.2151L40.7801 16.3121ZM20.0946 6.02837L17.1395 10.1655L12.8842 6.02837H20.0946ZM4.37352 6.02837L17.7305 19.1489L19.74 16.3121H38.5343L36.5248 19.1489H17.7305H4.37352V6.02837Z" />
  </svg>
)
export default AmpersandMarkIcon
