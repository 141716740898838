import {
  ButtonHTMLAttributes,
  FC,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react"
import clsx from "clsx"
import * as Dialog from "@radix-ui/react-dialog"
import dynamic from "next/dynamic"
import { useChatContext } from "stream-chat-react"
import useToast from "@/lib/useToast"
import useGlobalNav from "@/lib/useGlobalNav"
import useAuth from "@/hooks/useAuth"

import MessageCircleIcon from "@/icons/MessageCircle"
import { XXSmall } from "@/styles/Type"

const MessagingOverlay = dynamic(() => import("./Overlay"), { ssr: false })

export const MessagingTrigger: FC<
  ButtonHTMLAttributes<HTMLButtonElement> & { admin?: boolean }
> = ({ className, admin = false, ...props }) => {
  const { client } = useChatContext()
  const { user, masquerade } = useAuth()
  const { messagingIsOpen } = useGlobalNav()
  const [wasOpen, setWasOpen] = useState(false)

  const [unreadCount, setUnreadCount] = useState(0)
  const { addToast } = useToast()

  // Check filtered channels for unread messages
  const fetchUnreadMessages = useCallback(() => {
    const fetchCount = () => {
      return client
        ?.queryChannels({
          type: "messaging",
          members: { $in: [user?.stream_id] },
          ...(admin
            ? {}
            : {
                $or: [
                  {
                    from_profile_slug: { $eq: masquerade?.slug },
                  },
                  {
                    to_profile_slugs: { $in: [[masquerade?.slug]] },
                  },
                ],
              }),
        })
        .then((channels) =>
          channels.reduce((acc, channel) => acc + channel.countUnread(), 0)
        )
        .then(setUnreadCount)
    }

    const newMessageListener = client?.on(
      "notification.message_new",
      fetchCount
    )
    const readListener = client?.on("notification.mark_read", fetchCount)

    fetchCount()

    return () => {
      newMessageListener?.unsubscribe()
      readListener?.unsubscribe()
    }
  }, [admin, client, masquerade?.slug, user?.stream_id])

  useEffect(() => fetchUnreadMessages(), [fetchUnreadMessages])

  const isFirstRun = useRef(true)
  useEffect(() => {
    const showMessageToday = () => {
      const lastShown = localStorage.getItem("lastToastShown")
      const today = new Date().toDateString()

      if (lastShown === today) {
        return false
      }

      localStorage.setItem("lastToastShown", today)
      return true
    }

    if (isFirstRun.current) {
      isFirstRun.current = false
      return
    }

    if (unreadCount > 0 && showMessageToday()) {
      addToast(`You have ${unreadCount} new messages.`)
    }
  }, [addToast, unreadCount])

  useEffect(() => {
    if (wasOpen) {
      return
    }
    if (messagingIsOpen) {
      setWasOpen(true)
    }
    if (typeof window !== "undefined" && "requestIdleCallback" in window) {
      window.requestIdleCallback(() => {
        setWasOpen(true)
      })
    }
  }, [messagingIsOpen, wasOpen])

  // useEffect(() => {
  //   if (unreadCount > 0) {
  //     addToast(`You have ${unreadCount} new messages.`)
  //   }
  // }, [unreadCount])

  return (
    <Dialog.Root
      open={messagingIsOpen}
      // onOpenChange={setMessagingIsOpen}
      // modal={messagingIsOpen}
      modal={false}
    >
      <Dialog.Trigger asChild>
        <button
          type="button"
          className={clsx(
            className,
            "text-cream text-opacity-51 hover:text-opacity-100 transition duration-150"
          )}
          {...props}
        >
          <span className="sr-only">View Messages</span>
          <MessageCircleIcon className="inline-block" />
          {unreadCount > 0 && (
            <div className="w-5 h-auto  rounded-full bg-rio inline-block align-top ml-0.5 -mr-1">
              <XXSmall className="text-white">{unreadCount}</XXSmall>
            </div>
          )}
        </button>
      </Dialog.Trigger>
      <Dialog.Portal style={{ pointerEvents: "all" }} forceMount>
        {messagingIsOpen && (
          <div
            className={clsx(
              "fixed inset-0 bg-black bg-opacity-60 z-[50]",
              "animate-fade-in"
            )}
          />
        )}
        {wasOpen && (
          <Dialog.Content forceMount>
            <MessagingOverlay
              // admin={admin}
              className={clsx(
                !messagingIsOpen && "translate-x-full invisible",
                "transition-[transform,visibility] duration-300"
              )}
            />
          </Dialog.Content>
        )}
      </Dialog.Portal>
    </Dialog.Root>
  )
}

export default MessagingTrigger
