import type { SVGProps } from "react"

export const AllIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0.5 5.5H15.5M5.5 15.5V5.5M2.16667 0.5H13.8333C14.7538 0.5 15.5 1.24619 15.5 2.16667V13.8333C15.5 14.7538 14.7538 15.5 13.8333 15.5H2.16667C1.24619 15.5 0.5 14.7538 0.5 13.8333V2.16667C0.5 1.24619 1.24619 0.5 2.16667 0.5Z"
      stroke="#161616"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default AllIcon
