"use client"

import {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react"

const SCREENS = {
  masquerade: "MASQUERADE",
  "my-team": "MY_TEAM",
  "my-account": "MY_ACCOUNT",
  "team-payment-info": "TEAM_PAYMENT_INFO",
  "2fa-setup": "MFA_SETUP",
  "add-team-member": "TEAM_INVITE",
  "single-sign-on": "SINGLE_SIGN_ON",
}

export const GlobalNavContext = createContext({
  /* eslint-disable @typescript-eslint/no-unused-vars */
  showOverlay: null as string | null,
  messagingIsOpen: false,
  setMessagingIsOpen: (b) => {},
  /* eslint-enable @typescript-eslint/no-unused-vars */
})

export const GlobalNavContextProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [showOverlay, setShowOverlay] = useState<string>(null)
  const [messagingIsOpen, setMessagingIsOpen] = useState(false)

  const showOverlayOnHash = useCallback((url) => {
    const hash = url.split("#")[1]
    setShowOverlay(SCREENS[hash] || null)
  }, [])

  useEffect(() => {
    const onChange = (e: HashChangeEvent) => {
      showOverlayOnHash(e.newURL)
    }
    showOverlayOnHash(window.location.hash)
    window.addEventListener("hashchange", onChange)

    return () => {
      window.removeEventListener("hashchange", onChange)
    }
  }, [showOverlayOnHash])

  const fields = useMemo(
    () => ({
      showOverlay,
      messagingIsOpen,
      setMessagingIsOpen,
    }),
    [showOverlay, messagingIsOpen, setMessagingIsOpen]
  )

  return (
    <GlobalNavContext.Provider value={fields}>
      {children}
    </GlobalNavContext.Provider>
  )
}
export default GlobalNavContext
