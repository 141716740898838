import { FC, HTMLAttributes, useState } from "react"
import * as DropdownMenu from "@radix-ui/react-dropdown-menu"
import clsx from "clsx"

import useAuth from "hooks/useAuth"
import Image from "components/Image"
import { SerifH5 } from "@/styles/Type"
import UserMenu from "."

export const UserMenuOpener: FC<HTMLAttributes<HTMLDivElement>> = ({
  className,
}) => {
  const { masquerade, user } = useAuth()
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div
      className={clsx(
        "flex items-center px-2.5 -mr-2.5 lg:px-0 lg:mr-0 order-last",
        "transition duration-150",
        className
      )}
    >
      <DropdownMenu.Root open={isOpen} onOpenChange={setIsOpen} modal={false}>
        <DropdownMenu.Trigger className="flex w-8 h-8 rounded-full bg-quill overflow-hidden relative">
          <div
            className={clsx(
              isOpen && "opacity-0 lg:opacity-100",
              "transition duration-150"
            )}
          >
            {user?.profile_image ? (
              <Image
                className="rounded-full"
                src={user?.profile_image}
                alt={[user?.first_name, user?.last_name].join(" ")}
                width="32"
                height="32"
                sizes="40px"
              />
            ) : (
              <div className="w-[32px] h-[32px] object-cover bg-swisscafe rounded-full flex justify-center items-center">
                <SerifH5 className="text-[#454542] mt-1">
                  {" "}
                  {user?.first_name?.charAt(0)}
                </SerifH5>
              </div>
            )}
            {masquerade?.type &&
            (("logo" in masquerade && masquerade?.logo?.image_src) ||
              masquerade?.hero?.image_src) ? (
              <Image
                className="rounded-full"
                src={user?.profile_image}
                alt={[user?.first_name, user?.last_name].join(" ")}
                width="32"
                height="32"
                sizes="40px"
              />
            ) : (
              <div className="w-[32px] h-[32px] object-cover bg-swisscafe rounded-full flex justify-center items-center">
                <SerifH5 className="text-[#454542] mt-1">
                  {" "}
                  {user?.first_name?.charAt(0)}
                </SerifH5>
              </div>
            )}
          </div>
          <div
            className={clsx(
              "absolute inset-0 lg:hidden p-5 bg-offblack",
              !isOpen && "opacity-0",
              "lg:opacity-0",
              "transition duration-150"
            )}
          >
            <div
              className={clsx(
                "w-5 h-px bg-cream",
                "rotate-45 -translate-x-1/2 -translate-y-1/2",
                "absolute left-1/2 top-1/2",
                "rounded-full"
              )}
            />
            <div
              className={clsx(
                "w-5 h-px bg-cream",
                "-rotate-45 -translate-x-1/2 -translate-y-1/2",
                "absolute left-1/2 top-1/2",
                "rounded-full"
              )}
            />
          </div>
        </DropdownMenu.Trigger>
        {isOpen && (
          <div
            className={clsx(
              "fixed inset-0 -z-10",
              "transform-none",
              "animate-fade-in"
            )}
          />
        )}
        <UserMenu />
      </DropdownMenu.Root>
    </div>
  )
}
export default UserMenuOpener
